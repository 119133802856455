var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-app flex-row align-items-center min-vh-100"},[_c('CContainer',{attrs:{"fluid":""}},[_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{attrs:{"md":"6"}},[_c('CCard',{staticClass:"mx-4 mb-0"},[_c('CCardBody',{staticClass:"p-4"},[_c('CForm',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('h3',{style:({ 'font-size': '30px' })},[_vm._v("Regístrate")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Crea tu cuenta para acceder a WoWChecker ")]),_c('CRow',{staticClass:"mb-4",attrs:{"form":""}},[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"id":"name","name":"name","placeholder":"Username","autocomplete":"username","addInputClasses":{
                      error: _vm.$v.username.$error,
                    }},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{style:({ color: '#fab700' }),attrs:{"name":"cil-user"}})]},proxy:true}]),model:{value:(_vm.$v.username.$model),callback:function ($$v) {_vm.$set(_vm.$v.username, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.username.$model"}}),(_vm.$v.username.$dirty && !_vm.$v.username.required)?_c('span',{staticClass:"error"},[_vm._v("El nombre de usuario es requerido.")]):_vm._e(),(_vm.$v.username.$dirty && !_vm.$v.username.minLength)?_c('span',{staticClass:"error"},[_vm._v("La longitud mínima es de 4 caracteres.")]):_vm._e()],1)],1),_c('CRow',{staticClass:"mb-4",attrs:{"form":""}},[_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"placeholder":"Password","type":"password","id":"password","name":"password","autocomplete":"new-password","addInputClasses":{
                      error: _vm.$v.password.$error,
                    }},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{style:({ color: '#fab700' }),attrs:{"name":"cil-lock-locked"}})]},proxy:true}]),model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}}),(_vm.$v.password.$dirty && !_vm.$v.password.required)?_c('span',{staticClass:"error"},[_vm._v("Rellena la contraseña.")]):_vm._e()],1),_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"placeholder":"Repeat password","type":"password","id":"repeatPassword","addInputClasses":{
                      error: _vm.$v.repeatPassword.$error,
                    },"autocomplete":"new-password"},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{style:({ color: '#fab700' }),attrs:{"name":"cil-lock-locked"}})]},proxy:true}]),model:{value:(_vm.$v.repeatPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.repeatPassword, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.repeatPassword.$model"}}),(
                      _vm.$v.repeatPassword.$dirty &&
                      !_vm.$v.repeatPassword.sameAsPassword
                    )?_c('span',{staticClass:"error"},[_vm._v("Las contraseñas deben coincidir.")]):_vm._e()],1)],1),_c('CButton',{attrs:{"color":"warning","block":""},on:{"click":_vm.register}},[_vm._v("Crear cuenta")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }